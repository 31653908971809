import React, { useState } from 'react';

import { isIncluded } from '../utilites';

type UseAddToCartAndWishlistReturnType = {
  addToBasket: boolean;
  handleOnClickAddToWishList: (
    event: React.MouseEvent<HTMLButtonElement>
  ) => void;
  handleOnClickAddToCart: (event: React.MouseEvent<HTMLButtonElement>) => void;
};
const useAddToCartAndWishlist = (
  productId: string,
  wishListProductIds: string[],
  addToCartAction: (productId: string) => void,
  addToWishListAction: (productId: string) => void,
  removeFromWishListAction: (productId: string) => void
): UseAddToCartAndWishlistReturnType => {
  const [addToBasket, setAddToBasket] = useState(false);

  const handleOnClickAddToWishList = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();

    if (isIncluded(wishListProductIds, productId)) {
      return removeFromWishListAction(productId);
    }
    return addToWishListAction(productId);
  };

  const handleOnClickAddToCart = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setAddToBasket(true);

    addToCartAction(productId);

    if (isIncluded(wishListProductIds, productId)) {
      removeFromWishListAction(productId);
    }

    setTimeout(() => {
      setAddToBasket(false);
    }, 500);
  };

  return { addToBasket, handleOnClickAddToWishList, handleOnClickAddToCart };
};

export default useAddToCartAndWishlist;
