import { IAuthor, IAuthorRefDetails } from './types';

export default class Author implements IAuthor {
  _id: string;

  oldId: number;

  authorName: string;

  status: boolean;

  authorRefDetails: IAuthorRefDetails;

  authorImg?: string;

  description?: string;

  authorType?: string;

  metaTitle?: string;

  metaDescription?: string;

  authorSrRef?: string;

  authorEnRef?: string;

  authorRuRef?: string;

  authorHuRef?: string;

  defaultLanguage?: string;

  constructor(
    id: string,
    oldId: number,
    authorName: string,
    status: boolean,
    authorRefDetails: IAuthorRefDetails,
    authorImg?: string,
    description?: string,
    authorType?: string,
    metaTitle?: string,
    metaDescription?: string,
    authorSrRef?: string,
    authorEnRef?: string,
    authorRuRef?: string,
    authorHuRef?: string,
    defaultLanguage?: string
  ) {
    this._id = id;
    this.oldId = oldId;
    this.authorName = authorName;
    this.status = status;
    this.authorRefDetails = authorRefDetails;
    this.authorImg = authorImg;
    this.description = description;
    this.authorType = authorType;
    this.metaTitle = metaTitle;
    this.metaDescription = metaDescription;
    this.authorSrRef = authorSrRef;
    this.authorEnRef = authorEnRef;
    this.authorRuRef = authorRuRef;
    this.authorHuRef = authorHuRef;
    this.defaultLanguage = defaultLanguage;
  }
}
