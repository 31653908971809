import React, { FC } from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { useMediaQuery } from 'react-responsive';
import { getCategoryTabs } from '../../../utilites/product';
import './scss/style.scss';

interface IProps {
  tabs: any[];
  activeTab: string;
  handleOnChangeTab: (name: string) => void;
  isScrollable?: boolean;
}

const CategoryLinks: FC<IProps> = ({
  tabs,
  activeTab,
  handleOnChangeTab,
  isScrollable
}: IProps) => {
  const isTablet = useMediaQuery({ query: '(max-width: 995px)' });

  return (
    <div
      className={`container jost-semi-bold text-16 ${
        isScrollable ? 'horizontal-scrollable' : ''
      }`}
    >
      <div
        className={`row ${
          tabs.length < 4 ? 'justify-content-md-center' : ''
        } justify-content-lg-center text-center mb-3 ms-0 me-0 gx-0 align-items-center`}
      >
        {isTablet && isScrollable && tabs.length > 2 && (
          <div className="col-auto mt-minus-10 align-items-center">
            <MdKeyboardArrowLeft size="1.5em" className="me-3" />
          </div>
        )}
        {getCategoryTabs(tabs).map((item: any) => (
          <div
            key={item.name}
            onClick={() => handleOnChangeTab(item.name)}
            className={`${tabs.length > 2 ? 'col-6 col-sm-4 col-md-3' : ''} ${
              tabs.length < 4 ? 'col col-lg-2' : 'col-lg'
            } radiusLink me-sm-4 me-2 mb-3 mb-lg-0 p-2 cursor-pointer
               ${
                 item.name === activeTab
                   ? 'active-tab color-white'
                   : 'font-color-default'
               }
               `}
          >
            {item.count ? `${item.name} ` + `(${item.count})` : item.name}
          </div>
        ))}
        {isTablet && isScrollable && tabs.length > 2 && (
          <div className="col-auto mt-minus-10 align-items-center">
            <MdKeyboardArrowRight size="1.5em" className="mt-minus-10" />
          </div>
        )}
      </div>
    </div>
  );
};

export default CategoryLinks;
