import styled from 'styled-components';
import { IRibbonProps } from './types';

export const StyledRibbon = styled.div<IRibbonProps>`
  &::before {
    border-top: ${({ borderTopColor }) => `10px solid ${borderTopColor}`};
    border-right: 18px solid transparent;
    border-left: 0 none;
    border-bottom: 0 none;
    right: 0;
    left: auto;
    bottom: -10px;
    top: auto;
    content: '';
    position: absolute;
  }
`;
