import ActionTypes from '../../../constants/ActionTypes';
import {
  IAuthorPageDataState,
  IFetchAuthorPageDataAction,
  ISetAuthorPageDataAction,
  IValidateAuthorPageDataAction,
  IEnrichAuthorPageDataAction
} from './types';

export function fetchAuthorPageDataAction(
  id: string
): IFetchAuthorPageDataAction {
  return {
    type: ActionTypes.AUTHOR_PAGE.FETCH_AUTHOR_PAGE_DATA,
    payload: id
  };
}

export function setAuthorPageDataAction(
  authorPageData: IAuthorPageDataState
): ISetAuthorPageDataAction {
  return {
    type: ActionTypes.AUTHOR_PAGE.SET_AUTHOR_PAGE_DATA,
    payload: authorPageData
  };
}

export function validateAuthorPageDataAction(
  authorPageData: IAuthorPageDataState
): IValidateAuthorPageDataAction {
  return {
    type: ActionTypes.AUTHOR_PAGE.VALIDATE_AUTHOR_PAGE_DATA,
    payload: authorPageData
  };
}

export function enrichAuthorPageDataAction(
  authorPageData: IAuthorPageDataState
): IEnrichAuthorPageDataAction {
  return {
    type: ActionTypes.AUTHOR_PAGE.ENRICH_AUTHOR_PAGE_DATA,
    payload: authorPageData
  };
}
