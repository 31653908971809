import React, {
  FC,
  ReactChildren,
  ReactElement,
  ReactNode,
  useRef
} from 'react';
import './scss/style.scss';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/esm/types';
import { MdInfoOutline } from 'react-icons/md';
import { Link } from 'react-router-dom';
import ProductActions from '../../../constants/ProductActions';
import Config from '../../../config';
import ActionDefaultUrl from '../../../constants/ActionDefaultUrl';

interface IProps {
  title: string;
  isShow: boolean;
  onMouseLeave: () => void;
  onMouseEnter: () => void;
  children: ReactElement | ReactNode | ReactChildren | any;
  type: string;
  isTablet?: boolean;
  classes?: string;
  headerColor?: string;
  placement?: Placement;
  description?: string;
  infoIcon?: ReactElement | ReactNode | null;
  infoUrl?: string | null;
}

const DelfiPopover: FC<IProps> = ({
  title,
  isShow,
  children,
  type,
  onMouseLeave,
  onMouseEnter,
  isTablet,
  classes,
  placement = 'top',
  headerColor,
  description,
  infoIcon,
  infoUrl
}: IProps) => {
  const target = useRef(undefined);
  const overlayProps: any = {};

  if (!isTablet) {
    overlayProps.show = isShow;
  }

  const getUrlForPopup = () => {
    if (infoUrl) {
      return infoUrl;
    }

    if (Object.values(ProductActions).includes(type)) {
      return ActionDefaultUrl;
    }

    return '/delfi-premium';
  };

  return (
    <OverlayTrigger
      placement={placement}
      rootClose
      trigger={isTablet ? 'click' : ['hover', 'focus']}
      {...overlayProps}
      target={target.current}
      overlay={
        <Popover
          className="delfi-popover justify-content-center text-center"
          onMouseLeave={onMouseLeave}
          onMouseEnter={onMouseEnter}
        >
          <style>{`.popover-arrow::after { border-bottom-color: ${headerColor} !important; }`}</style>
          <Popover.Header
            as="h3"
            className="color-white line-height-20 p-1 text-14"
            style={{
              backgroundColor: headerColor
            }}
          >
            {title}
          </Popover.Header>
          <Popover.Body className="text-14 font-color-default">
            <div>{description}</div>
            <div>
              <Link
                to={getUrlForPopup()}
                className="font-color-default no-color-hover"
              >
                <MdInfoOutline size="1.5em" />
              </Link>
            </div>
          </Popover.Body>
        </Popover>
      }
    >
      {children}
    </OverlayTrigger>
  );
};

export default DelfiPopover;
