import React, { forwardRef, useEffect, useState } from 'react';
import { AiOutlineStar, AiFillStar } from 'react-icons/ai';
import NumberOfComments from '../number-of-comments';

interface IProps {
  rating?: number;
  classes?: string;
  size?: string;
  color?: string;
  label?: React.ReactElement;
  numberOfComments?: number;
  onlyEditMode?: boolean;
}

const StarRatings = forwardRef<HTMLInputElement, IProps>(
  (
    {
      rating,
      classes,
      size = '1rem',
      color = '#FBC920',
      label,
      numberOfComments,
      onlyEditMode
    }: IProps,
    ref
  ) => {
    const [starRating, setRating] = useState(
      rating && Number(rating) > 0 ? Math.round(Number(rating)) : 5
    );

    useEffect(() => {
      if (rating) {
        return setRating(rating);
      }

      setRating(5);
    }, [rating]);

    return (
      <div className={`align-items-center ${classes}`}>
        <div className={`ratings ${onlyEditMode ? 'cursor-pointer' : ''}`}>
          {label || null}
          {[...Array(5)].map((star, index) => {
            index += 1;
            if (index <= starRating) {
              return (
                <AiFillStar
                  key={index}
                  size={size}
                  color={color}
                  onClick={onlyEditMode ? () => setRating(index) : undefined}
                />
              );
            }
            return (
              <AiOutlineStar
                key={index}
                size={size}
                color={color}
                onClick={onlyEditMode ? () => setRating(index) : undefined}
              />
            );
          })}
          {numberOfComments ? (
            <NumberOfComments totalNumberOfComments={numberOfComments} />
          ) : null}
        </div>
        {onlyEditMode && (
          <input
            type="hidden"
            name="productRank"
            id="productRank"
            value={starRating}
            ref={ref}
          />
        )}
      </div>
    );
  }
);

export default StarRatings;
