import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import ApiUrls from '../../../constants/ApiUrls';
import { apiRequest } from '../api/actions';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';
import { setLoader } from '../ui/actions';
import {
  enrichAuthorPageDataAction,
  setAuthorPageDataAction,
  validateAuthorPageDataAction
} from './actions';
import { errorAction } from '../error-handling/actions';
import { HttpStatusCodes } from '../../../constants/HttpStatusCodes';
import NotificationTypes from '../../../constants/NotificationTypes';
import Ajv from 'ajv';
import { IProduct } from '../../../entities/product/types';
import { productSchema } from '../../../validators/product/productSchema';
import ProductFactory from '../../../factories/product';
import AuthorFactory from '../../../factories/author/AuthorFactory';

export const fetchAuthorPageDataMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);
    switch (action.type) {
      case ActionTypes.AUTHOR_PAGE.FETCH_AUTHOR_PAGE_DATA:
        next(
          apiRequest(
            null,
            'GET',
            `${ApiUrls.GET_AUTHOR_PAGE_DATA}/${action.payload}`,
            null,
            null,
            MiddlewareEntities.AUTHOR,
            {}
          )
        );
        next(setLoader(true, MiddlewareEntities.AUTHOR));
        break;
      case `${MiddlewareEntities.AUTHOR} ${ActionTypes.API_SUCCESS}`:
        next(setLoader(false, MiddlewareEntities.AUTHOR));
        next(validateAuthorPageDataAction(action.payload.data));
        break;
      case `${MiddlewareEntities.AUTHOR} ${ActionTypes.API_ERROR}`:
        next(setLoader(false, MiddlewareEntities.AUTHOR));

        if (
          action.payload.data &&
          action.payload.data.status &&
          action.payload.data.status === HttpStatusCodes.HTTP_NOT_FOUND
        ) {
          return (window.location.href = '/404');
        }

        next(
          errorAction(
            action.payload.data.data,
            MiddlewareEntities.AUTHOR,
            NotificationTypes.DELAY_NOTIFICATION_DISMISS,
            action.payload.data
              ? action.payload.data.status
              : HttpStatusCodes.HTTP_INTERNAL_SERVER_ERROR,
            action.payload.data && action.payload.data.code
              ? action.payload.data.code
              : undefined
          )
        );
        break;
    }
  };

export const validateAuthorPageDataMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (
      action.type.includes(ActionTypes.AUTHOR_PAGE.VALIDATE_AUTHOR_PAGE_DATA)
    ) {
      const ajv = new Ajv();
      const validProducts = action.payload.products.filter(
        (product: IProduct) => ajv.validate(productSchema, product)
      );

      next(
        enrichAuthorPageDataAction({
          author: action.payload.author,
          products: validProducts
        })
      );
    }
  };

export const enrichAuthorPageDataMiddleware: Middleware =
  (store: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (action.type === ActionTypes.AUTHOR_PAGE.ENRICH_AUTHOR_PAGE_DATA) {
      const products = action.payload.products.map((product: IProduct) => {
        return ProductFactory.create(product);
      });
      next(
        setAuthorPageDataAction({
          author: AuthorFactory.create(action.payload.author),
          products
        })
      );
    }
  };

export default [
  fetchAuthorPageDataMiddleware,
  validateAuthorPageDataMiddleware,
  enrichAuthorPageDataMiddleware
];
