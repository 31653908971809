import React, { CSSProperties, FC } from 'react';
import './scss/style.scss';

interface IProps {
  number: number;
  styleType?: CSSProperties;
  classes?: string;
  numberClasses?: string;
}

const ProductNumber: FC<IProps> = ({
  number,
  styleType,
  classes,
  numberClasses
}: IProps) => (
  <div
    className={`position-absolute text-center number-component d-flex justify-content-center align-items-center ${classes}`}
    style={styleType}
  >
    <span className={`jost-semi-bold ${numberClasses}`}>{number}</span>
  </div>
);

export default ProductNumber;
