import { StyledRibbon } from '../product-stickers/StyledRibbon';
import React, { FC, useRef } from 'react';
import DelfiPopover from '../popover';
import { ISticker } from '../../../entities/product/types';
import useHover from '../../../hooks/useHover';

interface IProps {
  item: ISticker;
  isTablet?: boolean;
}

const Sticker: FC<IProps> = ({ item, isTablet }: IProps) => {
  const ref = useRef(null);
  const { isShow, onMouseEnter, onMouseLeave } = useHover();
  return (
    <div
      onClick={(event) => event.preventDefault()}
      className={`${isTablet ? 'cursor-pointer' : ''}`}
    >
      <DelfiPopover
        title={item.popup.title}
        type={item.type}
        description={item.popup.description}
        placement="bottom"
        headerColor={item.color}
        isTablet={isTablet}
        isShow={isShow}
        onMouseLeave={onMouseLeave}
        onMouseEnter={onMouseEnter}
        infoUrl={item.infoUrl}
      >
        <div
          style={{ backgroundColor: item.color }}
          className="justify-content-center align-items-center text-center mt-2 position-relative"
          ref={ref}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <p className="text-14 jost-semi-bold line-height-16 m-0 p-1">
            {item.title}
          </p>
          <StyledRibbon borderTopColor={item.color} />
        </div>
      </DelfiPopover>
    </div>
  );
};

export default Sticker;
