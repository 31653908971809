import React, { FC } from 'react';

interface IProps {
  totalNumberOfComments: number;
}

const NumberOfComments: FC<IProps> = ({ totalNumberOfComments }: IProps) => {
  return (
    <span className="ms-2 text-muted text-12 jost-medium">
      {totalNumberOfComments > 0
        ? totalNumberOfComments > 1
          ? '( ' + totalNumberOfComments + ' ' + 'komentara )'
          : '( ' + totalNumberOfComments + ' ' + 'komentar )'
        : ''}
    </span>
  );
};

export default NumberOfComments;
