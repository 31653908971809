import React, { FC } from 'react';
import './scss/style.scss';
import { ISticker } from '../../../entities/product/types';
import { useMediaQuery } from 'react-responsive';
import Sticker from '../sticker';

interface IProps {
  stickers: ISticker[];
  classes?: string;
}

const ProductStickers: FC<IProps> = ({ stickers, classes }: IProps) => {
  const isTablet = useMediaQuery({ query: '(max-width: 1000px)' });
  return (
    <div className={`position-absolute mt-1 discount ${classes}`}>
      {stickers.map((item: ISticker, index) => {
        return (
          <Sticker
            key={item.title + '_' + index}
            item={item}
            isTablet={isTablet}
          />
        );
      })}
    </div>
  );
};

export default ProductStickers;
