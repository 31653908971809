import MiddlewareEntities from './MiddlewareEntities';

export const ActionTypes = {
  SET_LOADER: `${MiddlewareEntities.UI} SET_LOADER`,
  SHOW_LOADER: 'SHOW_LOADER',
  HIDE_LOADER: 'HIDE_LOADER',
  API_REQUEST: 'API_REQUEST',
  API_SUCCESS: 'API_SUCCESS',
  API_ERROR: 'API_ERROR',
  NOT_FOUND: 'NOT_FOUND',
  ERROR_FORBIDDEN: 'ERROR_FORBIDDEN',
  ERROR: 'ERROR',
  INIT: 'INIT',
  SET_API_RESPONSE_IN_CACHE: 'SET_API_RESPONSE_IN_CACHE',
  SEARCH_ITEMS: {
    FETCH_ITEMS_BY_KEY: `${MiddlewareEntities.SEARCH_ITEMS} FETCH_ITEMS_BY_KEY`,
    SET_ITEMS_BY_KEY: `${MiddlewareEntities.SEARCH_ITEMS} SET_ITEMS_BY_KEY`,
    SET_INIT_SEARCH_ITEMS: `${MiddlewareEntities.SEARCH_ITEMS} SET_INIT_SEARCH_ITEMS`,
    VALIDATE_SEARCH_ITEMS: `${MiddlewareEntities.SEARCH_ITEMS} VALIDATE_SEARCH_ITEMS`,
    ENRICH_SEARCH_ITEMS: `${MiddlewareEntities.SEARCH_ITEMS} ENRICH_SEARCH_ITEMS`
  },
  TEXT_SLIDERS: {
    FETCH_TEXT_SLIDERS: `${MiddlewareEntities.TEXT_SLIDERS} FETCH_TEXT_SLIDERS`,
    SET_TEXT_SLIDERS: `${MiddlewareEntities.TEXT_SLIDERS} SET_TEXT_SLIDERS`
  },
  MENU_ITEMS: {
    FETCH_MENU_ITEMS: `${MiddlewareEntities.MENU_ITEMS} FETCH_MENU_ITEMS`,
    SET_MENU_ITEMS: `${MiddlewareEntities.MENU_ITEMS} SET_MENU_ITEMS`,
    VALIDATE_MENU_ITEMS: `${MiddlewareEntities.MENU_ITEMS} VALIDATE_MENU_ITEMS`,
    ENRICH_MENU_ITEMS: `${MiddlewareEntities.MENU_ITEMS} ENRICH_MENU_ITEMS`
  },
  HOMEPAGE_TOP_CAROUSEL: {
    FETCH_HOMEPAGE_TOP_CAROUSEL: `${MiddlewareEntities.HOMEPAGE_TOP_CAROUSEL} FETCH_HOMEPAGE_TOP_CAROUSEL`,
    SET_HOMEPAGE_TOP_CAROUSEL: `${MiddlewareEntities.HOMEPAGE_TOP_CAROUSEL} SET_HOMEPAGE_TOP_CAROUSEL`
  },
  HOMEPAGE: {
    FETCH_HOMEPAGE: `${MiddlewareEntities.HOMEPAGE} FETCH_HOMEPAGE`,
    SET_HOMEPAGE: `${MiddlewareEntities.HOMEPAGE} SET_HOMEPAGE`,
    VALIDATE_HOMEPAGE: `${MiddlewareEntities.HOMEPAGE} VALIDATE_HOMEPAGE`,
    ENRICH_HOMEPAGE: `${MiddlewareEntities.HOMEPAGE} ENRICH_HOMEPAGE`
  },
  PRODUCTS_LIST_PAGE: {
    FETCH_PRODUCTS_LIST_PAGE_PRODUCTS: `${MiddlewareEntities.PRODUCTS_LIST_PAGE_PRODUCTS} FETCH_PRODUCTS_LIST_PAGE_PRODUCTS`,
    SET_PRODUCTS_LIST_PAGE_PRODUCTS: `${MiddlewareEntities.PRODUCTS_LIST_PAGE_PRODUCTS} SET_PRODUCTS_LIST_PAGE_PRODUCTS`,
    VALIDATE_PRODUCTS_LIST_PAGE_PRODUCTS: `${MiddlewareEntities.PRODUCTS_LIST_PAGE_PRODUCTS} VALIDATE_PRODUCTS_LIST_PAGE_PRODUCTS`,
    ENRICH_PRODUCTS_LIST_PAGE_PRODUCTS: `${MiddlewareEntities.PRODUCTS_LIST_PAGE_PRODUCTS} ENRICH_PRODUCTS_LIST_PAGE_PRODUCTS`,
    FETCH_PRODUCTS_LIST_GENRES: `${MiddlewareEntities.GENRES} FETCH_PRODUCTS_LIST_GENRES`,
    SET_PRODUCTS_LIST_PAGE_GENRES: `${MiddlewareEntities.GENRES} SET_PRODUCTS_LIST_PAGE_GENRES`
  },
  PRODUCT_PAGE: {
    FETCH_PRODUCT_PAGE: `${MiddlewareEntities.PRODUCT_PAGE} FETCH_PRODUCT_PAGE`,
    SET_PRODUCT_PAGE: `${MiddlewareEntities.PRODUCT_PAGE} SET_PRODUCT_PAGE`,
    VALIDATE_PRODUCT_PAGE: `${MiddlewareEntities.PRODUCT_PAGE} VALIDATE_PRODUCT_PAGE`,
    ENRICH_PRODUCT_PAGE: `${MiddlewareEntities.PRODUCT_PAGE} ENRICH_PRODUCT_PAGE`,
    FETCH_AVAILABLE_BOOKSTORES: `${MiddlewareEntities.BOOKSTORES} FETCH_AVAILABLE_BOOKSTORES`,
    SET_AVAILABLE_BOOKSTORES: `${MiddlewareEntities.BOOKSTORES} SET_AVAILABLE_BOOKSTORES`,
    VALIDATE_AVAILABLE_BOOKSTORES: `${MiddlewareEntities.BOOKSTORES} VALIDATE_AVAILABLE_BOOKSTORES`,
    ENRICH_AVAILABLE_BOOKSTORES: `${MiddlewareEntities.BOOKSTORES} ENRICH_AVAILABLE_BOOKSTORES`
  },
  PRODUCT_BY_NAV_ID: {
    FETCH_PRODUCT_BY_NAV_ID: `${MiddlewareEntities.PRODUCT_BY_NAV_ID} FETCH_PRODUCT_BY_NAV_ID`,
    SET_PRODUCT_BY_NAV_ID: `${MiddlewareEntities.PRODUCT_BY_NAV_ID} SET_PRODUCT_BY_NAV_ID`,
    RESET_PRODUCT_BY_NAV_ID: `${MiddlewareEntities.PRODUCT_BY_NAV_ID} RESET_PRODUCT_BY_NAV_ID`,
    VALIDATE_PRODUCT_BY_NAV_ID: `${MiddlewareEntities.PRODUCT_BY_NAV_ID} VALIDATE_PRODUCT_BY_NAV_ID`,
    ENRICH_PRODUCT_BY_NAV_ID: `${MiddlewareEntities.PRODUCT_BY_NAV_ID} ENRICH_PRODUCT_BY_NAV_ID`
  },
  BOOKSTORES_PAGE: {
    FETCH_BOOKSTORES_PAGE: `${MiddlewareEntities.BOOKSTORES} FETCH_BOOKSTORES_PAGE`,
    SET_BOOKSTORES_PAGE: `${MiddlewareEntities.BOOKSTORES} SET_BOOKSTORES_PAGE`,
    VALIDATE_BOOKSTORES_PAGE: `${MiddlewareEntities.BOOKSTORES} VALIDATE_BOOKSTORES_PAGE`,
    ENRICH_BOOKSTORES_PAGE: `${MiddlewareEntities.BOOKSTORES} ENRICH_BOOKSTORES_PAGE`
  },
  BOOKSTORE_OVERVIEW_PAGE: {
    FETCH_BOOKSTORE_OVERVIEW_PAGE: `${MiddlewareEntities.BOOKSTORES} FETCH_BOOKSTORE_OVERVIEW_PAGE`,
    SET_BOOKSTORE_OVERVIEW_PAGE: `${MiddlewareEntities.BOOKSTORES} SET_BOOKSTORE_OVERVIEW_PAGE`,
    VALIDATE_BOOKSTORE_OVERVIEW_PAGE: `${MiddlewareEntities.BOOKSTORES} VALIDATE_BOOKSTORE_OVERVIEW_PAGE`,
    ENRICH_BOOKSTORE_OVERVIEW_PAGE: `${MiddlewareEntities.BOOKSTORES} ENRICH_BOOKSTORE_OVERVIEW_PAGE`
  },
  USER: {
    FETCH_USER: `${MiddlewareEntities.USER} FETCH_USER`,
    SET_USER: `${MiddlewareEntities.USER} SET_USER`,
    USER_LOGIN: `${MiddlewareEntities.USER} USER_LOGIN`,
    USER_LOGOUT: `${MiddlewareEntities.USER} USER_LOGOUT`,
    CREATE_USER: `${MiddlewareEntities.CREATE_USER} CREATE_USER`,
    SET_USER_MESSAGE: `${MiddlewareEntities.CREATE_USER} SET_USER_MESSAGE`,
    REMOVE_USER_COOKIE: `${MiddlewareEntities.USER} REMOVE_USER_COOKIE`
  },
  PROFILE_PAGE: {
    FETCH_PROFILE_USER: `${MiddlewareEntities.PROFILE_USER} FETCH_PROFILE_USER`,
    SET_PROFILE_USER: `${MiddlewareEntities.PROFILE_USER} SET_PROFILE_USER`,
    FETCH_PROFILE_ACTIVITY: `${MiddlewareEntities.PROFILE_ACTIVITY} FETCH_PROFILE_ACTIVITY`,
    SET_PROFILE_ACTIVITY: `${MiddlewareEntities.PROFILE_ACTIVITY} SET_PROFILE_ACTIVITY`,
    VALIDATE_PROFILE_ACTIVITY: `${MiddlewareEntities.PROFILE_ACTIVITY} VALIDATE_PROFILE_ACTIVITY`,
    ENRICH_PROFILE_ACTIVITY: `${MiddlewareEntities.PROFILE_ACTIVITY} ENRICH_PROFILE_ACTIVITY`,
    DELETE_PROFILE_USER: `${MiddlewareEntities.DELETE_PROFILE_USER} DELETE_PROFILE_USER`,
    FETCH_PROFILE_ORDER_HISTORY: `${MiddlewareEntities.PROFILE_ORDER_HISTORY} FETCH_PROFILE_ORDER_HISTORY`,
    VALIDATE_PROFILE_ORDER_HISTORY: `${MiddlewareEntities.PROFILE_ORDER_HISTORY} VALIDATE_PROFILE_ORDER_HISTORY`,
    ENRICH_PROFILE_ORDER_HISTORY: `${MiddlewareEntities.PROFILE_ORDER_HISTORY} ENRICH_PROFILE_ORDER_HISTORY`,
    SET_PROFILE_ORDER_HISTORY: `${MiddlewareEntities.PROFILE_ORDER_HISTORY} SET_PROFILE_ORDER_HISTORY`,
    PROFILE_USER_UPDATE: `${MiddlewareEntities.PROFILE_USER_UPDATE} PROFILE_USER_UPDATE`,
    PROFILE_USER_PASSWORD_CHANGE: `${MiddlewareEntities.PROFILE_USER_PASSWORD_CHANGE} PROFILE_USER_PASSWORD_CHANGE`,
    SET_PROFILE_USER_MESSAGE: `${MiddlewareEntities.PROFILE_USER_UPDATE} SET_PROFILE_USER_MESSAGE`,
    JOIN_TO_DELFI_PREMIUM: `${MiddlewareEntities.PROFILE_USER} JOIN_TO_DELFI_PREMIUM`
  },
  COMMENTS: {
    FETCH_ALL_COMMENTS: `${MiddlewareEntities.COMMENTS} FETCH_ALL_COMMENTS`,
    SET_ALL_COMMENTS: `${MiddlewareEntities.COMMENTS} SET_ALL_COMMENTS`,
    ADD_COMMENT: `${MiddlewareEntities.COMMENT} ADD_COMMENT`,
    SET_ADD_COMMENT_STATUS: `${MiddlewareEntities.COMMENT} SET_ADD_COMMENT_STATUS`
  },
  NEWS: {
    FETCH_LAST_NEWS: `${MiddlewareEntities.NEWS} FETCH_LAST_NEWS`,
    FETCH_NEWS_SECTIONS: `${MiddlewareEntities.NEWS_SECTION} FETCH_NEWS_SECTIONS`,
    ENRICH_NEWS_SECTIONS: `${MiddlewareEntities.NEWS_SECTION} ENRICH_NEWS_SECTIONS`,
    SET_NEWS_SECTIONS: `${MiddlewareEntities.NEWS_SECTION} SET_NEWS_SECTIONS`,
    SET_LAST_NEWS: `${MiddlewareEntities.NEWS} SET_LAST_NEWS`,
    VALIDATE_NEWS: `${MiddlewareEntities.NEWS} VALIDATE_NEWS`,
    ENRICH_NEWS: `${MiddlewareEntities.NEWS} ENRICH_NEWS`
  },
  NEWS_OVERVIEW: {
    FETCH_NEWS_OVERVIEW: `${MiddlewareEntities.NEWS_OVERVIEW} FETCH_NEWS_OVERVIEW`,
    SET_NEWS_OVERVIEW: `${MiddlewareEntities.NEWS_OVERVIEW} SET_NEWS_OVERVIEW`,
    VALIDATE_NEWS_OVERVIEW: `${MiddlewareEntities.NEWS_OVERVIEW} VALIDATE_NEWS_OVERVIEW`,
    ENRICH_NEWS_OVERVIEW: `${MiddlewareEntities.NEWS_OVERVIEW} ENRICH_NEWS_OVERVIEW`
  },
  COUNTRIES: {
    FETCH_COUNTRIES: `${MiddlewareEntities.COUNTRY} FETCH_COUNTRIES`,
    SET_COUNTRIES: `${MiddlewareEntities.COUNTRY} SET_COUNTRIES`
  },
  MUNICIPALITIES: {
    FETCH_MUNICIPALITIES: `${MiddlewareEntities.COUNTRY} FETCH_MUNICIPALITIES`,
    SET_MUNICIPALITIES: `${MiddlewareEntities.COUNTRY} SET_MUNICIPALITIES`
  },
  WISHLIST: {
    ADD_TO_WISHLIST: `${MiddlewareEntities.WISHLIST} ADD_TO_WISHLIST`,
    REMOVE_FROM_WISHLIST: `${MiddlewareEntities.WISHLIST} REMOVE_FROM_WISHLIST`,
    SET_WISHLIST: `${MiddlewareEntities.WISHLIST} SET_WISHLIST`,
    FETCH_WISHLIST_FROM_COOKIE: `${MiddlewareEntities.WISHLIST} FETCH_WISHLIST_FROM_COOKIE`,
    FETCH_WISHLIST_REQUEST: `${MiddlewareEntities.WISHLIST} FETCH_WISHLIST_REQUEST`,
    VALIDATE_WISHLIST: `${MiddlewareEntities.WISHLIST} VALIDATE_WISHLIST`,
    ENRICH_WISHLIST: `${MiddlewareEntities.WISHLIST} ENRICH_WISHLIST`,
    SET_WISHLIST_FROM_API: `${MiddlewareEntities.WISHLIST} SET_WISHLIST_FROM_API`,
    SAVE_TO_WISHLIST_REQUEST: `${MiddlewareEntities.WISHLIST} SAVE_TO_WISHLIST_REQUEST`,
    SET_INITIAL_WISHLIST: `${MiddlewareEntities.WISHLIST} SET_INITIAL_WISHLIST`
  },
  CONFIRM_USER_REGISTRATION: `${MiddlewareEntities.USER} CONFIRM_USER_REGISTRATION`,
  SET_USER_CONFIRMATION_MESSAGE: `${MiddlewareEntities.USER} SET_USER_CONFIRMATION_MESSAGE`,
  CART: {
    ADD_TO_CART: `${MiddlewareEntities.CART} ADD_TO_CART`,
    REMOVE_FROM_CART: `${MiddlewareEntities.CART} REMOVE_FROM_CART`,
    CART_CHANGE_QUANTITY: `${MiddlewareEntities.CART} CART_CHANGE_QUANTITY`,
    SET_PRODUCT_TO_CART: `${MiddlewareEntities.CART} SET_PRODUCT_TO_CART`,
    SET_PRODUCT_QUANTITY_TO_CART: `${MiddlewareEntities.CART} SET_PRODUCT_QUANTITY_TO_CART`,
    SET_CART_FOR_PAYMENT: `${MiddlewareEntities.CART_FOR_PAYMENT} SET_CART_FOR_PAYMENT`,
    FETCH_CART_ITEMS_FROM_API: `${MiddlewareEntities.FETCH_CART_ITEMS} FETCH_CART_ITEMS_FROM_API`,
    VALIDATE_CART: `${MiddlewareEntities.CART} VALIDATE_CART`,
    ENRICH_CART: `${MiddlewareEntities.CART} ENRICH_CART`,
    REMOVE_ALL_ITEMS_FROM_CART: `${MiddlewareEntities.CART} REMOVE_ALL_ITEMS_FROM_CART`,
    CART_CHANGE_COUNTRY_AND_ZIP_CODE: `${MiddlewareEntities.CART} CART_CHANGE_COUNTRY_AND_ZIP_CODE`,
    RESET_CART: `${MiddlewareEntities.CART} RESET_CART`,
    SET_IS_LAST_GET_CART_ERROR: `${MiddlewareEntities.CART} SET_IS_LAST_GET_CART_ERROR`
  },
  LAST_VIEWED: {
    ADD_TO_LAST_VIEWED: `${MiddlewareEntities.LAST_VIEWED} ADD_TO_LAST_VIEWED`,
    SET_LAST_VIEWED_TO_COOKIE: `${MiddlewareEntities.LAST_VIEWED} SET_LAST_VIEWED_TO_COOKIE`,
    FETCH_LAST_VIEWED_FROM_COOKIE: `${MiddlewareEntities.LAST_VIEWED} FETCH_LAST_VIEWED_FROM_COOKIE`,
    FETCH_LAST_VIEWED_PRODUCTS_FROM_API: `${MiddlewareEntities.LAST_VIEWED} FETCH_LAST_VIEWED_PRODUCTS_FROM_API`,
    VALIDATE_LAST_VIEWED_PRODUCTS: `${MiddlewareEntities.LAST_VIEWED} VALIDATE_LAST_VIEWED_PRODUCTS`,
    ENRICH_LAST_VIEWED_PRODUCTS: `${MiddlewareEntities.LAST_VIEWED} ENRICH_LAST_VIEWED_PRODUCTS`,
    SET_LAST_VIEWED_PRODUCTS: `${MiddlewareEntities.LAST_VIEWED} SET_LAST_VIEWED_PRODUCTS`
  },
  WSPAY: {
    FETCH_WSPAY_REDIRECT_PARAMS_FROM_API: `${MiddlewareEntities.FETCH_WSPAY_REDIRECT_PARAMS} FETCH_WSPAY_REDIRECT_PARAMS_FROM_API`,
    SET_WSPAY_REDIRECT_PARAMS: `${MiddlewareEntities.FETCH_WSPAY_REDIRECT_PARAMS} SET_WSPAY_REDIRECT_PARAMS`
  },
  ASK_ABOUT_PRODUCT: {
    ASK_ABOUT_PRODUCT_POST_REQUEST: `${MiddlewareEntities.ASK_ABOUT_PRODUCT} ASK_ABOUT_PRODUCT_POST_REQUEST`,
    SET_ASK_ABOUT_PRODUCT_MESSAGE_STATUS_ACTION: `${MiddlewareEntities.ASK_ABOUT_PRODUCT} SET_ASK_ABOUT_PRODUCT_MESSAGE_STATUS_ACTION`
  },
  KIDS_BOOK: {
    FETCH_KIDS_BOOK_DATA: `${MiddlewareEntities.KIDS_BOOK} FETCH_KIDS_BOOK_DATA`,
    SET_KIDS_BOOK_DATA: `${MiddlewareEntities.KIDS_BOOK} SET_KIDS_BOOK_DATA`,
    VALIDATE_KIDS_BOOK_DATA: `${MiddlewareEntities.KIDS_BOOK} VALIDATE_KIDS_BOOK_DATA`,
    ENRICH_KIDS_BOOK_DATA: `${MiddlewareEntities.KIDS_BOOK} ENRICH_KIDS_BOOK_DATA`
  },
  FOREIGN_BOOK: {
    FETCH_FOREIGN_BOOK_DATA: `${MiddlewareEntities.FOREIGN_BOOK} FETCH_FOREIGN_BOOK_DATA`,
    SET_FOREIGN_BOOK_DATA: `${MiddlewareEntities.FOREIGN_BOOK} SET_FOREIGN_BOOK_DATA`,
    VALIDATE_FOREIGN_BOOK_DATA: `${MiddlewareEntities.FOREIGN_BOOK} VALIDATE_FOREIGN_BOOK`,
    ENRICH_FOREIGN_BOOK_DATA: `${MiddlewareEntities.FOREIGN_BOOK} ENRICH_FOREIGN_BOOK_DATA`
  },
  SCHOOL_BOOK: {
    FETCH_SCHOOL_BOOK_DATA: `${MiddlewareEntities.FOREIGN_BOOK} FETCH_SCHOOL_BOOK_DATA`,
    SET_SCHOOL_BOOK_DATA: `${MiddlewareEntities.FOREIGN_BOOK} SET_SCHOOL_BOOK_DATA`,
    VALIDATE_SCHOOL_BOOK_DATA: `${MiddlewareEntities.FOREIGN_BOOK} VALIDATE_SCHOOL_BOOK_DATA`,
    ENRICH_SCHOOL_BOOK_DATA: `${MiddlewareEntities.FOREIGN_BOOK} ENRICH_SCHOOL_BOOK_DATA`
  },
  STARWAY_PAGE: {
    FETCH_STARWAY_DATA: `${MiddlewareEntities.STARWAY} FETCH_STARWAY_DATA`,
    SET_STARWAY_DATA: `${MiddlewareEntities.STARWAY} SET_SCHOOL_BOOK_DATA`,
    VALIDATE_STARWAY_DATA: `${MiddlewareEntities.STARWAY} VALIDATE_SCHOOL_BOOK_DATA`,
    ENRICH_STARWAY_DATA: `${MiddlewareEntities.STARWAY} ENRICH_SCHOOL_BOOK_DATA`
  },
  ACTIONS_PAGE: {
    FETCH_ACTIONS_PAGE_DATA: `${MiddlewareEntities.ACTIONS} FETCH_ACTIONS_PAGE_DATA`,
    SET_ACTIONS_PAGE_DATA: `${MiddlewareEntities.ACTIONS} SET_ACTIONS_PAGE_DATA`,
    VALIDATE_ACTIONS_PAGE_DATA: `${MiddlewareEntities.ACTIONS} VALIDATE_ACTIONS_PAGE_DATA`,
    ENRICH_ACTIONS_PAGE_DATA: `${MiddlewareEntities.ACTIONS} ENRICH_ACTIONS_PAGE_DATA`
  },
  ACTIONS_LIST_PAGE: {
    FETCH_ACTIONS_LIST_PAGE_DATA: `${MiddlewareEntities.ACTIONS_LIST} FETCH_ACTIONS_LIST_PAGE_DATA`,
    SET_ACTIONS_LIST_PAGE_DATA: `${MiddlewareEntities.ACTIONS_LIST} SET_ACTIONS_LIST_PAGE_DATA`,
    VALIDATE_ACTIONS_LIST_PAGE_DATA: `${MiddlewareEntities.ACTIONS_LIST} VALIDATE_ACTIONS_LIST_PAGE_DATA`,
    ENRICH_ACTIONS_LIST_PAGE_DATA: `${MiddlewareEntities.ACTIONS_LIST} ENRICH_ACTIONS_LIST_PAGE_DATA`
  },
  PREMIUM_ACTIONS_PAGE: {
    FETCH_PREMIUM_ACTIONS_PAGE_DATA: `${MiddlewareEntities.PREMIUM_ACTIONS} FETCH_PREMIUM_ACTIONS_PAGE_DATA`,
    SET_PREMIUM_ACTIONS_PAGE_DATA: `${MiddlewareEntities.PREMIUM_ACTIONS} SET_PREMIUM_ACTIONS_PAGE_DATA`,
    VALIDATE_PREMIUM_ACTIONS_PAGE_DATA: `${MiddlewareEntities.PREMIUM_ACTIONS} VALIDATE_PREMIUM_ACTIONS_PAGE_DATA`,
    ENRICH_PREMIUM_ACTIONS_PAGE_DATA: `${MiddlewareEntities.PREMIUM_ACTIONS} ENRICH_PREMIUM_ACTIONS_PAGE_DATA`
  },
  BOOK_PAGE: {
    FETCH_BOOK_PAGE_DATA: `${MiddlewareEntities.BOOK} FETCH_BOOK_PAGE_DATA`,
    SET_BOOK_PAGE_DATA: `${MiddlewareEntities.BOOK} SET_BOOK_PAGE_DATA`,
    VALIDATE_BOOK_PAGE_DATA: `${MiddlewareEntities.BOOK} VALIDATE_BOOK_PAGE_DATA`,
    ENRICH_BOOK_PAGE_DATA: `${MiddlewareEntities.BOOK} ENRICH_BOOK_PAGE_DATA`
  },
  GAME_PAGE: {
    FETCH_GAME_PAGE_DATA: `${MiddlewareEntities.GAME_PAGE} FETCH_GAME_PAGE_DATA`,
    SET_GAME_PAGE_DATA: `${MiddlewareEntities.GAME_PAGE} SET_GAME_PAGE_DATA`,
    VALIDATE_GAME_PAGE_DATA: `${MiddlewareEntities.GAME_PAGE} VALIDATE_GAME_PAGE_DATA`,
    ENRICH_GAME_PAGE_DATA: `${MiddlewareEntities.GAME_PAGE} ENRICH_GAME_PAGE_DATA`
  },
  GIFT_PAGE: {
    FETCH_GIFT_PAGE_DATA: `${MiddlewareEntities.GIFT_PAGE} FETCH_GIFT_PAGE_DATA`,
    SET_GIFT_PAGE_DATA: `${MiddlewareEntities.GIFT_PAGE} SET_GIFT_PAGE_DATA`,
    VALIDATE_GIFT_PAGE_DATA: `${MiddlewareEntities.GIFT_PAGE} VALIDATE_GIFT_PAGE_DATA`,
    ENRICH_GIFT_PAGE_DATA: `${MiddlewareEntities.GIFT_PAGE} ENRICH_GIFT_PAGE_DATA`
  },
  MUSIC_PAGE: {
    FETCH_MUSIC_PAGE_DATA: `${MiddlewareEntities.MUSIC} FETCH_MUSIC_PAGE_DATA`,
    SET_MUSIC_PAGE_DATA: `${MiddlewareEntities.MUSIC} SET_MUSIC_PAGE_DATA`,
    VALIDATE_MUSIC_PAGE_DATA: `${MiddlewareEntities.MUSIC} VALIDATE_MUSIC_PAGE_DATA`,
    ENRICH_MUSIC_PAGE_DATA: `${MiddlewareEntities.MUSIC} ENRICH_MUSIC_PAGE_DATA`
  },
  FILM_PAGE: {
    FETCH_FILM_PAGE_DATA: `${MiddlewareEntities.FILM_PAGE} FETCH_FILM_PAGE_DATA`,
    SET_FILM_PAGE_DATA: `${MiddlewareEntities.FILM_PAGE} SET_FILM_PAGE_DATA`,
    VALIDATE_FILM_PAGE_DATA: `${MiddlewareEntities.FILM_PAGE} VALIDATE_FILM_PAGE_DATA`,
    ENRICH_FILM_PAGE_DATA: `${MiddlewareEntities.FILM_PAGE} ENRICH_FILM_PAGE_DATA`
  },
  BOARD_GAMES_PAGE: {
    FETCH_BOARD_GAMES_PAGE_DATA: `${MiddlewareEntities.BOARD_GAMES_PAGE} FETCH_BOARD_GAMES_PAGE_DATA`,
    SET_BOARD_GAMES_PAGE_DATA: `${MiddlewareEntities.BOARD_GAMES_PAGE} SET_BOARD_GAMES_PAGE_DATA`,
    VALIDATE_BOARD_GAMES_PAGE_DATA: `${MiddlewareEntities.BOARD_GAMES_PAGE} VALIDATE_BOARD_GAMES_PAGE_DATA`,
    ENRICH_BOARD_GAMES_PAGE_DATA: `${MiddlewareEntities.BOARD_GAMES_PAGE} ENRICH_BOARD_GAMES_PAGE_DATA`
  },
  MANGE_PAGE: {
    FETCH_MANGE_PAGE_DATA: `${MiddlewareEntities.MANGE_PAGE} FETCH_MANGE_PAGE_DATA`,
    SET_MANGE_PAGE_DATA: `${MiddlewareEntities.MANGE_PAGE} SET_MANGE_PAGE_DATA`,
    VALIDATE_MANGE_PAGE_DATA: `${MiddlewareEntities.MANGE_PAGE} VALIDATE_MANGE_PAGE_DATA`,
    ENRICH_MANGE_PAGE_DATA: `${MiddlewareEntities.MANGE_PAGE} ENRICH_MANGE_PAGE_DATA`
  },
  MAGYAR_PAGE: {
    FETCH_MAGYAR_PAGE_DATA: `${MiddlewareEntities.MAGYAR_PAGE} FETCH_MAGYAR_PAGE_DATA`,
    SET_MAGYAR_PAGE_DATA: `${MiddlewareEntities.MAGYAR_PAGE} SET_MAGYAR_PAGE_DATA`,
    VALIDATE_MAGYAR_PAGE_DATA: `${MiddlewareEntities.MAGYAR_PAGE} VALIDATE_MAGYAR_PAGE_DATA`,
    ENRICH_MAGYAR_PAGE_DATA: `${MiddlewareEntities.MAGYAR_PAGE} ENRICH_MAGYAR_PAGE_DATA`
  },
  RUSSIAN_BOOKS_PAGE: {
    FETCH_RUSSIAN_BOOKS_PAGE_DATA: `${MiddlewareEntities.RUSSIAN_BOOKS_PAGE} FETCH_RUSSIAN_BOOKS_PAGE_DATA`,
    SET_RUSSIAN_BOOKS_PAGE_DATA: `${MiddlewareEntities.RUSSIAN_BOOKS_PAGE} SET_RUSSIAN_BOOKS_PAGE_DATA`,
    VALIDATE_RUSSIAN_BOOKS_PAGE_DATA: `${MiddlewareEntities.RUSSIAN_BOOKS_PAGE} VALIDATE_RUSSIAN_BOOKS_PAGE_DATA`,
    ENRICH_RUSSIAN_BOOKS_PAGE_DATA: `${MiddlewareEntities.RUSSIAN_BOOKS_PAGE} ENRICH_RUSSIAN_BOOKS_PAGE_DATA`
  },
  ADMINISTRATIVE_BAN: {
    FETCH_ADMINISTRATIVE_BAN_DATA: `${MiddlewareEntities.ADMINISTRATIVE_BAN} FETCH_ADMINISTRATIVE_BAN_DATA`,
    VALIDATE_ADMINISTRATIVE_BAN_DATA: `${MiddlewareEntities.ADMINISTRATIVE_BAN} VALIDATE_ADMINISTRATIVE_BAN_DATA`,
    ENRICH_ADMINISTRATIVE_BAN_DATA: `${MiddlewareEntities.ADMINISTRATIVE_BAN} ENRICH_ADMINISTRATIVE_BAN_DATA`,
    SET_ADMINISTRATIVE_BAN_DATA: `${MiddlewareEntities.ADMINISTRATIVE_BAN} SET_ADMINISTRATIVE_BAN_DATA`
  },
  CHECKOUT: {
    SUBMIT_ORDER_DATA_STEP: `${MiddlewareEntities.CHECKOUT} SUBMIT_ORDER_DATA_STEP`,
    GO_TO_NEXT_STEP: `${MiddlewareEntities.CHECKOUT} GO_TO_NEXT_STEP`,
    GO_TO_PREVIOUS_STEP: `${MiddlewareEntities.CHECKOUT} GO_TO_PREVIOUS_STEP`,
    SET_CHECKOUT_DATA: `${MiddlewareEntities.CHECKOUT} SET_CHECKOUT_DATA`,
    RESET_CHECKOUT_DATA: `${MiddlewareEntities.CHECKOUT} RESET_CHECKOUT_DATA`
  },
  NOTIFICATION: {
    CREATE_NOTIFICATION: `${MiddlewareEntities.NOTIFICATION} CREATE_NOTIFICATION`,
    SET_NOTIFICATION: `${MiddlewareEntities.NOTIFICATION} SET_NOTIFICATION`,
    REMOVE_NOTIFICATION: `${MiddlewareEntities.NOTIFICATION} REMOVE_NOTIFICATION`,
    REMOVE_ALL_NOTIFICATIONS: `${MiddlewareEntities.NOTIFICATION} REMOVE_ALL_NOTIFICATIONS`,
    CREATE_ERROR_NOTIFICATION: `${MiddlewareEntities.NOTIFICATION} CREATE_ERROR_NOTIFICATION`
  },
  BOOKMAKER_MAGAZINE: {
    FETCH_BOOKMAKER_MAGAZINE_PAGE_DATA: `${MiddlewareEntities.BOOKMAKER_MAGAZINE} FETCH_BOOKMAKER_MAGAZINE_PAGE_DATA`,
    SET_BOOKMAKER_MAGAZINE_PAGE_DATA: `${MiddlewareEntities.BOOKMAKER_MAGAZINE} SET_BOOKMAKER_MAGAZINE_PAGE_DATA`,
    VALIDATE_BOOKMAKER_MAGAZINE_PAGE_DATA: `${MiddlewareEntities.BOOKMAKER_MAGAZINE} VALIDATE_BOOKMAKER_MAGAZINE_PAGE_DATA`,
    ENRICH_BOOKMAKER_MAGAZINE_PAGE_DATA: `${MiddlewareEntities.BOOKMAKER_MAGAZINE} ENRICH_BOOKMAKER_MAGAZINE_PAGE_DATA`
  },
  CAREER: {
    FETCH_CAREER_PAGE_DATA: `${MiddlewareEntities.CAREER} FETCH_CAREER_PAGE_DATA`,
    SET_CAREER_PAGE_DATA: `${MiddlewareEntities.CAREER} SET_CAREER_PAGE_DATA`,
    VALIDATE_CAREER_PAGE_DATA: `${MiddlewareEntities.CAREER} VALIDATE_CAREER_PAGE_DATA`,
    ENRICH_CAREER_PAGE_DATA: `${MiddlewareEntities.CAREER} ENRICH_CAREER_PAGE_DATA`,
    SUBMIT_USER_CAREER_DATA: `${MiddlewareEntities.CAREER} SUBMIT_USER_CAREER_DATA`
  },
  ADVANCED_SEARCH: {
    FETCH_ADVANCED_SEARCH_DATA: `${MiddlewareEntities.ADVANCED_SEARCH} FETCH_ADVANCE_SEARCH_DATA`,
    SET_ADVANCED_SEARCH_DATA: `${MiddlewareEntities.ADVANCED_SEARCH} SET_ADVANCED_SEARCH_DATA`,
    VALIDATE_ADVANCED_SEARCH_DATA: `${MiddlewareEntities.ADVANCED_SEARCH} VALIDATE_ADVANCED_SEARCH_DATA`,
    ENRICH_ADVANCED_SEARCH_DATA: `${MiddlewareEntities.CAREER} ENRICH_ADVANCED_SEARCH_DATA`
  },
  SEARCH_PARAMS: {
    CLEAR_SEARCH_PARAMS: `${MiddlewareEntities.SEARCH_PARAMS} CLEAR_SEARCH_PARAMS`,
    CREATE_SEARCH_PARAMS: `${MiddlewareEntities.SEARCH_PARAMS} CREATE_SEARCH_PARAMS`,
    SET_SEARCH_PARAMS: `${MiddlewareEntities.SEARCH_PARAMS} SET_SEARCH_PARAMS`
  },
  FAQ: {
    FETCH_FAQ_DATA: `${MiddlewareEntities.FAQ} FETCH_FAQ_DATA`,
    SET_FAQ_DATA: `${MiddlewareEntities.FAQ} SET_FAQ_DATA`
  },
  NEWSLETTER: {
    SUBSCRIBE_NEWSLETTER: `${MiddlewareEntities.NEWSLETTER} SUBSCRIBE_NEWSLETTER`,
    UNSUBSCRIBE_NEWSLETTER: `${MiddlewareEntities.NEWSLETTER_UNSUBSCRIBE} UNSUBSCRIBE_NEWSLETTER`,
    SET_SUBSCRIBE_NEWSLETTER_MESSAGE: `${MiddlewareEntities.NEWSLETTER} SET_SUBSCRIBE_NEWSLETTER_MESSAGE`,
    SET_UNSUBSCRIBE_NEWSLETTER_MESSAGE: `${MiddlewareEntities.NEWSLETTER_UNSUBSCRIBE} SET_UNSUBSCRIBE_NEWSLETTER_MESSAGE`,
    CONFIRM_NEWSLETTER: `${MiddlewareEntities.NEWSLETTER} CONFIRM_NEWSLETTER`,
    CONFIRM_NEWSLETTER_UNSUBSCRIBE: `${MiddlewareEntities.NEWSLETTER_UNSUBSCRIBE} CONFIRM_NEWSLETTER_UNSUBSCRIBE`,
    SET_NEWSLETTER_CONFIRMATION_MESSAGE: `${MiddlewareEntities.NEWSLETTER} SET_NEWSLETTER_CONFIRMATION_MESSAGE`,
    SET_NEWSLETTER_UNSUBSCRIBE_CONFIRMATION_MESSAGE: `${MiddlewareEntities.NEWSLETTER} SET_NEWSLETTER_UNSUBSCRIBE_CONFIRMATION_MESSAGE`
  },
  SECTION_PAGE: {
    FETCH_SECTION_PAGE_DATA: `${MiddlewareEntities.SECTION_PAGE} FETCH_SECTION_PAGE_DATA`,
    SET_SECTION_PAGE_DATA: `${MiddlewareEntities.SECTION_PAGE} SET_SECTION_PAGE_DATA`,
    VALIDATE_SECTION_PAGE_DATA: `${MiddlewareEntities.SECTION_PAGE} VALIDATE_SECTION_PAGE_DATA`,
    ENRICH_SECTION_PAGE_DATA: `${MiddlewareEntities.SECTION_PAGE} ENRICH_SECTION_PAGE_DATA`,
    FETCH_SECTION_PAGE_GENRES: `${MiddlewareEntities.GENRES} FETCH_SECTION_PAGE_GENRES`,
    SET_SECTION_PAGE_GENRES: `${MiddlewareEntities.GENRES} SET_SECTION_PAGE_GENRES`
  },
  FORGOT_USER_PASSWORD: {
    SEND_FORGOT_USER_PASSWORD_REQUEST: `${MiddlewareEntities.FORGOT_USER_PASSWORD} SEND_FORGOT_USER_PASSWORD_REQUEST`,
    SET_FORGOT_USER_PASSWORD: `${MiddlewareEntities.FORGOT_USER_PASSWORD} SET_FORGOT_USER_PASSWORD`
  },
  RESET_USER_PASSWORD: {
    SEND_RESET_USER_PASSWORD_REQUEST: `${MiddlewareEntities.RESET_USER_PASSWORD}`,
    SET_RESET_USER_PASSWORD: `${MiddlewareEntities.RESET_USER_PASSWORD} SET_RESET_USER_PASSWORD`
  },
  EDEN_BOOKS: {
    EDEN_BOOKS_CREATE_ORDER: `${MiddlewareEntities.EDEN_BOOKS_CREATE_ORDER} EDEN_BOOKS_CREATE_ORDER`,
    SEND_FORGOT_USER_EDEN_PASSWORD_REQUEST: `${MiddlewareEntities.EDEN_BOOKS_USER_FORGOT_PASSWORD} SEND_FORGOT_USER_EDEN_PASSWORD_REQUEST`,
    SET_FORGOT_USER_EDEN_PASSWORD: `${MiddlewareEntities.EDEN_BOOKS_USER_FORGOT_PASSWORD} SET_FORGOT_USER_EDEN_PASSWORD`,
    SEND_RESET_USER_EDEN_PASSWORD_REQUEST: `${MiddlewareEntities.EDEN_BOOKS_USER_RESET_PASSWORD} SEND_RESET_USER_EDEN_PASSWORD_REQUEST`,
    SET_RESET_USER_EDEN_PASSWORD_STATUS: `${MiddlewareEntities.EDEN_BOOKS_USER_RESET_PASSWORD} SET_RESET_USER_EDEN_PASSWORD_STATUS`,
    SET_EDEN_ERROR_MESSAGE: `${MiddlewareEntities.EDEN_BOOKS_ERROR_MESSAGE} SET_EDEN_ERROR_MESSAGE`
  },
  ORDER_STATUS: {
    FETCH_ORDER: `${MiddlewareEntities.ORDER_STATUS} FETCH_ORDER`,
    VALIDATE_ORDER: `${MiddlewareEntities.ORDER_STATUS} VALIDATE_ORDER`,
    ENRICH_ORDER: `${MiddlewareEntities.ORDER_STATUS} ENRICH_ORDER`,
    SET_ORDER: `${MiddlewareEntities.ORDER_STATUS} SET_ORDER`
  },
  STATIC_PAGE: {
    FETCH_STATIC_PAGE_DATA: `${MiddlewareEntities.STATIC_PAGE} FETCH_STATIC_PAGE_DATA`,
    SET_STATIC_PAGE_DATA: `${MiddlewareEntities.STATIC_PAGE} SET_STATIC_PAGE_DATA`
  },
  COMPLAINT_PAGE: {
    FETCH_COMPLAINT_PAGE_DATA: `${MiddlewareEntities.COMPLAINT} FETCH_COMPLAINT_PAGE_DATA`,
    SET_COMPLAINT_PAGE_DATA: `${MiddlewareEntities.COMPLAINT} SET_COMPLAINT_PAGE_DATA`,
    SUBMIT_COMPLAINT_DATA: `${MiddlewareEntities.COMPLAINT} SUBMIT_COMPLAINT_DATA`
  },
  AVAILABLE_NOTIFICATION: {
    SET_AVAILABLE_NOTIFICATION: `${MiddlewareEntities.AVAILABLE_NOTIFICATION} SET_AVAILABLE_NOTIFICATION`,
    SUBMIT_AVAILABLE_NOTIFICATION: `${MiddlewareEntities.AVAILABLE_NOTIFICATION} SUBMIT_AVAILABLE_NOTIFICATION`,
    CLEAR_AVAILABLE_NOTIFICATION: `${MiddlewareEntities.AVAILABLE_NOTIFICATION} CLEAR_AVAILABLE_NOTIFICATION`
  },
  BOOKTOK_PAGE: {
    FETCH_BOOKTOK_PAGE_DATA: `${MiddlewareEntities.BOOKTOK} FETCH_BOOKTOK_PAGE_DATA`,
    SET_BOOKTOK_PAGE_DATA: `${MiddlewareEntities.BOOKTOK} SET_BOOKTOK_PAGE_DATA`,
    VALIDATE_BOOKTOK_PAGE_DATA: `${MiddlewareEntities.BOOKTOK} VALIDATE_BOOKTOK_PAGE_DATA`,
    ENRICH_BOOKTOK_PAGE_DATA: `${MiddlewareEntities.BOOKTOK} ENRICH_BOOKTOK_PAGE_DATA`
  },
  TOP_LISTS_PAGE: {
    FETCH_TOP_LISTS_PAGE_DATA: `${MiddlewareEntities.TOP_LISTS} FETCH_TOP_LISTS_PAGE_DATA`,
    SET_TOP_LISTS_PAGE_DATA: `${MiddlewareEntities.TOP_LISTS} SET_TOP_LISTS_PAGE_DATA`,
    VALIDATE_TOP_LISTS_PAGE_DATA: `${MiddlewareEntities.TOP_LISTS} VALIDATE_TOP_LISTS_PAGE_DATA`,
    ENRICH_TOP_LISTS_PAGE_DATA: `${MiddlewareEntities.TOP_LISTS} ENRICH_TOP_LISTS_PAGE_DATA`
  },
  GTM: {
    SEND_EVENT_ADD_PRODUCT: `${MiddlewareEntities.GTM} SEND_EVENT_ADD_PRODUCT`,
    SEND_EVENT_REMOVE_PRODUCT: `${MiddlewareEntities.GTM} SEND_EVENT_REMOVE_PRODUCT`,
    SEND_EVENT_BEGIN_CHECKOUT: `${MiddlewareEntities.GTM} SEND_EVENT_BEGIN_CHECKOUT`
  },
  TOGGLE_CHANGE_CART: {
    SET_TOGGLE_CHANGE_CART: `${MiddlewareEntities.CART} SET_TOGGLE_CHANGE_CART`
  },
  REDIRECT_PAGE: {
    FETCH_AUTHOR_ID_BY_OLD_ID: `${MiddlewareEntities.REDIRECT_AUTHOR_BY_OLD_ID} FETCH_AUTHOR_ID_BY_OLD_ID`,
    FETCH_GENRE_NAME_BY_OLD_ID: `${MiddlewareEntities.REDIRECT_GENRE_BY_OLD_ID} FETCH_GENRE_NAME_BY_OLD_ID`
  },
  FILTERS_DATA: {
    FETCH_FILTERS_DATA: `${MiddlewareEntities.FILTERS_DATA} FETCH_FILTERS_DATA`,
    SET_FILTERS_DATA: `${MiddlewareEntities.FILTERS_DATA} SET_FILTERS_DATA`,
    ENRICH_FILTERS_DATA: `${MiddlewareEntities.FILTERS_DATA} ENRICH_FILTERS_DATA`
  },
  NOT_FOUND_URL: {
    UPDATE_NOT_FOUND_URL: `${MiddlewareEntities.NOT_FOUND_URL} UPDATE_NOT_FOUND_URL`
  },
  COOKIE_CONSENT: {
    FETCH_COOKIE_CONSENT: `${MiddlewareEntities.COOKIE_CONSENT} FETCH_COOKIE_CONSENT`,
    CREATE_COOKIE_CONSENT: `${MiddlewareEntities.COOKIE_CONSENT} CREATE_COOKIE_CONSENT`,
    SET_COOKIE_CONSENT: `${MiddlewareEntities.COOKIE_CONSENT} SET_COOKIE_CONSENT`
  },
  AUTHOR_PAGE: {
    FETCH_AUTHOR_PAGE_DATA: `${MiddlewareEntities.AUTHOR} FETCH_AUTHOR_PAGE_DATA`,
    SET_AUTHOR_PAGE_DATA: `${MiddlewareEntities.AUTHOR} SET_AUTHOR_PAGE_DATA`,
    VALIDATE_AUTHOR_PAGE_DATA: `${MiddlewareEntities.AUTHOR} VALIDATE_AUTHOR_PAGE_DATA`,
    ENRICH_AUTHOR_PAGE_DATA: `${MiddlewareEntities.AUTHOR} ENRICH_AUTHOR_PAGE_DATA`
  }
};

export default ActionTypes;
