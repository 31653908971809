import React, { FC } from 'react';
import './scss/style.scss';
import Pagination from 'react-bootstrap/Pagination';

interface IProps {
  itemsCount: number;
  itemsPerPage: number;
  currentPage: number;
  setCurrentPage: (pageNumber: number) => void;
  alwaysShown: boolean;
  isScrollToTopDisabled?: boolean;
}

const PaginationComponent: FC<IProps> = ({
  itemsCount,
  itemsPerPage,
  currentPage,
  setCurrentPage,
  alwaysShown,
  isScrollToTopDisabled
}: IProps) => {
  const pagesCount = Math.ceil(itemsCount / itemsPerPage);
  const isPaginationShown = alwaysShown ? true : pagesCount > 1;
  const isCurrentPageFirst = currentPage === 1;
  const isCurrentPageLast = currentPage === pagesCount;

  const changePage = (pageNumber: number) => {
    if (currentPage !== pageNumber) {
      setCurrentPage(pageNumber);
      if (!isScrollToTopDisabled) {
        window.scrollTo(0, 0);
      }
    }
  };

  const onPageNumberClick = (pageNumber: number) => {
    changePage(pageNumber);
  };

  const onPreviousPageClick = () => {
    const previousPageNumber = currentPage - 1;
    //@ts-ignore
    changePage(previousPageNumber);
  };

  const onNextPageClick = () => {
    const nextPageNumber = currentPage + 1;
    //@ts-ignore
    changePage(nextPageNumber);
  };

  let isPageNumberOutOfRange: boolean;

  const pageNumbers = [...new Array(pagesCount)].map((_, index) => {
    const pageNumber = index + 1;
    const isPageNumberFirst = pageNumber === 1;
    const isPageNumberLast = pageNumber === pagesCount;
    const isCurrentPageWithinTwoPageNumbers =
      Math.abs(pageNumber - currentPage) <= 2;

    if (
      isPageNumberFirst ||
      isPageNumberLast ||
      isCurrentPageWithinTwoPageNumbers
    ) {
      isPageNumberOutOfRange = false;
      return (
        <Pagination.Item
          key={pageNumber}
          onClick={() => onPageNumberClick(pageNumber)}
          active={pageNumber === currentPage}
        >
          {pageNumber}
        </Pagination.Item>
      );
    }

    if (!isPageNumberOutOfRange) {
      isPageNumberOutOfRange = true;
      return <Pagination.Ellipsis key={pageNumber} className="muted" />;
    }

    return null;
  });

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      {isPaginationShown && (
        <Pagination>
          <Pagination.Prev
            onClick={onPreviousPageClick}
            disabled={isCurrentPageFirst}
          />
          {pageNumbers}
          <Pagination.Next
            onClick={onNextPageClick}
            disabled={isCurrentPageLast}
          />
        </Pagination>
      )}
    </div>
  );
};

export default PaginationComponent;
