import { useState } from 'react';

const useHover = () => {
  const [isShow, setShow] = useState(false);

  const onMouseEnter = () => {
    setShow(true);
  };

  const onMouseLeave = () => {
    setShow(false);
  };

  return { isShow, onMouseEnter, onMouseLeave };
};

export default useHover;
