import React, { FC } from 'react';
import DropdownMenu from '../../layout/header/dropdown';
import { Link } from 'react-router-dom';
import SingleDropdownItem from '../../layout/header/mobile/single-dropdown item';
import { FaCircle } from 'react-icons/fa';
import {
  IGenre,
  IGenresByCategory
} from '../../../app/store/filters-data/types';
import { keyId } from '../../layout/header/hooks/types';

interface IProps {
  genres: IGenresByCategory[];
  handleOnClickToggleNavDropdown: (
    event: React.MouseEvent<HTMLDivElement>,
    id: keyId
  ) => void;
  navItemsOpen: any;
  createGenreUrl: (genreName: string, category: string) => string;
  foundGenreInGenres: (genreName: string) => boolean;
}

const MobileGenres: FC<IProps> = ({
  genres,
  handleOnClickToggleNavDropdown,
  navItemsOpen,
  createGenreUrl,
  foundGenreInGenres
}: IProps) => {
  const getKey = (category: string): keyId => {
    switch (category) {
      case 'Knjige':
        return 'books';
      case 'Film':
        return 'film';
      case 'Strane knjige':
        return 'foreignBooks';
      case 'Muzika':
        return 'musics';
      case 'Gift':
        return 'gifts';
      default:
        return 'books';
    }
  };

  const createCategoryUrl = (category: string) => {
    switch (category) {
      case 'Knjige':
        return 'knjige';
      case 'Film':
        return 'film';
      case 'Strane knjige':
        return 'strane_knjige';
      case 'Muzika':
        return 'muzika';
      case 'Gift':
        return 'gift';
      default:
        return 'knjige';
    }
  };

  return (
    <>
      {genres.map((genre: IGenresByCategory) => {
        return (
          <DropdownMenu
            id={getKey(genre.category)}
            handleOnClick={handleOnClickToggleNavDropdown}
            showDropdown={navItemsOpen[getKey(genre.category)]}
            label={genre.category.toUpperCase()}
            borderClasses={`border-2 ${
              getKey(genre.category) === 'film' ||
              getKey(genre.category) === 'musics'
                ? 'border-dotted'
                : 'border-bottom'
            }`}
            textHeadlineClasses="text-14 jost-extra-bold"
            textClassesChildren="text-14 jost-semi-bold"
          >
            <li className="mb-2">
              <Link
                to={createCategoryUrl(genre.category)}
                className="text-decoration-none"
              >
                <span className="color-blue jost-bold">{genre.category}</span>
              </Link>
            </li>
            {genre.genres.map((gen: IGenre) => {
              return (
                <li className="mb-3">
                  <Link
                    onClick={(event) => {
                      return (
                        foundGenreInGenres(gen.genreName) &&
                        event.preventDefault()
                      );
                    }}
                    to={createGenreUrl(gen.genreName, genre.category)}
                    className="text-decoration-none"
                  >
                    <span
                      className={
                        foundGenreInGenres(gen.genreName) ? 'color-blue' : ''
                      }
                    >
                      {gen.genreName}
                    </span>
                  </Link>
                </li>
              );
            })}
          </DropdownMenu>
        );
      })}
      <SingleDropdownItem
        title="DEČJE KNJIGE"
        url="/lista-proizvoda?inPage=decja-knjiga&genre=Knjige za decu"
      />
      <SingleDropdownItem
        title="КНИГИ НА РУССКОМ ЯЗЫКЕ"
        url="/knigi-na-russkom"
      />
      <SingleDropdownItem title="MAGYAR" url="/magyar" />
      <SingleDropdownItem title="DRUŠTVENE IGRE" url="/drustvene-igre" />
      <SingleDropdownItem title="MANGE" url="/mange" />
      {/*<SingleDropdownItem title="ŠKOLA" url="/skola" />*/}
      <SingleDropdownItem title="E-KNJIGE" url="/lista-proizvoda?eBook=true" />
      <SingleDropdownItem title="TOP LISTE" url="/top-liste" />
      <SingleDropdownItem title="STARWAY" url="/starway" />
      <SingleDropdownItem title="BOOKTOK" url="/booktok" />
      <SingleDropdownItem
        title="AKCIJE"
        url="/akcije"
        icon={
          <FaCircle color="#FBC920" className="mt-minus-5 me-3" size="1em" />
        }
        iconDirection="left"
      />
      {/*<SingleDropdownItem title="TECH" url="/starway" />*/}
      <SingleDropdownItem title="#DELFI KUTAK" url="/vesti" />
      <SingleDropdownItem title="KNJIŽARE" url="/knjizare" />
    </>
  );
};

export default MobileGenres;
