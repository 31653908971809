import React, { FC } from 'react';
import { IAuthor } from '../../../entities/product/types';
import './scss/style.scss';
import { Link } from 'react-router-dom';
import { replaceStringWith } from '../../../utilites';
import { getFormatedAuthorUrl } from '../../../utilites/author';

interface IProps {
  authors: IAuthor[] | string;
  classes?: string;
  handleOnClick?: () => void;
}

const Authors: FC<IProps> = ({ authors, classes, handleOnClick }: IProps) => {
  let localAuthors: any = authors;

  return (
    <div className={classes}>
      {Array.isArray(localAuthors)
        ? localAuthors.map((author: any, index: number, authors) => {
            if (
              author.authorName.toLowerCase() !== 'grupa autora' &&
              author.authorName.toLowerCase() !== 'various' &&
              author.authorName.toLowerCase() !== 'razni autori' &&
              author.authorName.toLowerCase() !== 'razni izvođači'
            ) {
              return (
                <Link
                  key={author._id}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (typeof handleOnClick === 'function') {
                      handleOnClick();
                    }
                  }}
                  to={`/autori/${getFormatedAuthorUrl(
                    author._id,
                    author.authorName.toLowerCase()
                  )}`}
                  className={`text-muted d-block jost-medium text-decoration-none authors-link ${classes}`}
                >
                  {author.authorName}
                </Link>
              );
            }
            return (
              <span
                key={author._id}
                className={`text-muted d-block jost-medium text-decoration-none ${classes}`}
              >
                {author.authorName}
              </span>
            );
          })
        : localAuthors}
    </div>
  );
};

export default Authors;
